var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"pa-4"},[(
            _vm.userGroups.includes('camunda-admin') ||
              _vm.userGroups.includes('LgSpotFieldservice')
          )?_c('h3',[_vm._v(" Nachunternehmer: ")]):_vm._e(),_c('v-select',{attrs:{"label":"GF-NR","required":"","items":[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]},model:{value:(_vm.gfNumber),callback:function ($$v) {_vm.gfNumber=$$v},expression:"gfNumber"}}),_c('v-autocomplete',{attrs:{"label":"TA-Lage","required":"","items":[
            'Keller',
            'Garage',
            'Erdgeschoss',
            '1.Stock',
            '2.Stock',
            '3.Stock',
            '4.Stock'
          ]},model:{value:(_vm.taLocation),callback:function ($$v) {_vm.taLocation=$$v},expression:"taLocation"}}),_c('v-text-field',{attrs:{"label":"MFG-Spleißpanel","required":"","rules":[_vm.requiredRule]},model:{value:(_vm.mfgSplicePanel),callback:function ($$v) {_vm.mfgSplicePanel=$$v},expression:"mfgSplicePanel"}}),_c('v-text-field',{attrs:{"label":"MFG-Panelport","required":"","rules":[_vm.requiredRule]},model:{value:(_vm.mfgPanelPort),callback:function ($$v) {_vm.mfgPanelPort=$$v},expression:"mfgPanelPort"}}),_c('v-combobox',{ref:"ontSn",attrs:{"items":_vm.ontItems,"loading":_vm.loadingOnts,"hide-no-data":"","search-input":_vm.search,"item-text":_vm.getOntText,"item-value":_vm.serialNumber,"label":"ONT-Seriennummer","append-icon":"","placeholder":"ONT-Seriennummer eingeben","clearable":"","return-object":"","key-name":"serialNumber","rules":[_vm.ontRule]},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.ontSn),callback:function ($$v) {_vm.ontSn=$$v},expression:"ontSn"}}),_c('v-alert',{attrs:{"type":"info","text":"","border":"left"}},[_vm._v(" ONT-Seriennummer nicht verfügbar? Wenden Sie sich bitte an "),_c('a',{attrs:{"href":"mailto:access@goetel.de"}},[_vm._v("access@goetel.de")])]),_c('v-checkbox',{attrs:{"label":"OTDR-Protokoll vorhanden"},model:{value:(_vm.otdrProtocol),callback:function ($$v) {_vm.otdrProtocol=$$v},expression:"otdrProtocol"}}),_c('v-checkbox',{attrs:{"label":"Montagenachweis vorhanden"},model:{value:(_vm.proofOfInstallation),callback:function ($$v) {_vm.proofOfInstallation=$$v},expression:"proofOfInstallation"}}),_c('v-text-field',{attrs:{"label":"Bemerkung"},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1)],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":function($event){_vm.saveSupplyUnitConnection(), _vm.validate()}}},[_vm._v(" Speichern ")]),_c('br'),_c('br')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }