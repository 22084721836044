<template>
  <div class="d-flex">
    <v-row :class="{ 'form-loading': loading }">
      <v-col
        v-if="!(propertyResult && propertyResult.geo3857) && centered"
        md="3"
        transition="slide-x-transition"
      ></v-col>
      <v-col :md="[propertyResult && propertyResult.geo3857 ? 6 : 6]">
        <div :class="activeDistribution && !centered ? '' : 'rCard pa-10'">
          <h2
            v-if="!(propertyResult && propertyResult.geo3857) && !centered"
            class="mb-8"
          >
            Adresse eingeben
          </h2>
          <h2
            v-else-if="!(propertyResult && propertyResult.geo3857) && centered"
            class="mb-8"
          >
            Installationsadresse eingeben
          </h2>
          <AddressAutocomplete @update="updateAddress" />
          <div class="d-flex mt-10">
            <v-text-field
              v-model="errorTolerance"
              label="Fehlertoleranz in Meter"
              persistent-placeholder
              dense
            />
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="getProperty">
              Adresse suchen
            </v-btn>
          </div>
          <v-card v-if="propertyResult" class="mt-4">
            <v-card-title> Ergebnis </v-card-title>
            <v-card-text>
              <v-row>
                <v-col :md="8">
                  <template
                    v-if="propertyResult.addressIsValid && propertyResult.ags27"
                  >
                    <v-icon color="success">
                      mdi-check
                    </v-icon> Adresse ist
                    valide - AGS27: {{ propertyResult.ags27 }} <br />
                  </template>
                  <template v-else>
                    <v-icon color="error">
                      mdi-close
                    </v-icon> Adresse ist nicht
                    valide <br />
                  </template>
                  <template v-if="propertyResult.projectId">
                    <v-icon color="success">
                      mdi-check
                    </v-icon> Projekt-ID:
                    {{ propertyResult.projectId }} <br />
                  </template>
                  <template v-else>
                    <v-icon color="error">
                      mdi-close
                    </v-icon> Addresse befindet
                    sich nicht in einem Projektgebiet <br />
                  </template>
                  <template v-if="propertyResult.statusDescription">
                    <v-icon color="success">
                      mdi-check
                    </v-icon>
                    Projekt-Status: {{ propertyResult.statusDescription }}
                    <br />
                  </template>
                  <template
                    v-if="
                      propertyResult.errorToleranceMeters &&
                        propertyResult.errorToleranceMeters > 0
                    "
                  >
                    <v-icon color="warning">
                      mdi-alert-circle
                    </v-icon>
                    Fehlertoleranz
                    {{ propertyResult.errorToleranceMeters }} Meter
                    <br />
                  </template>
                </v-col>
                <v-col :md="4">
                  <template
                    v-if="
                      propertyResult.distributionStatusDescription &&
                        propertyResult.distributionStatusColor
                    "
                  >
                    Vertriebsphase: <br />
                    <v-alert
                      dense
                      text
                      border="left"
                      :color="propertyResult.distributionStatusColor"
                      class="my-2"
                    >
                      {{ propertyResult.distributionStatusDescription }}
                    </v-alert>
                  </template>
                  <br />
                  <template
                    v-if="propertyResult.tariffModelName && activeDistribution"
                  >
                    Standard-Tarifmodell:
                    <v-alert
                      dense
                      text
                      border="left"
                      type="success"
                      class="my-2"
                    >
                      {{ propertyResult.tariffModelName }}
                    </v-alert>
                  </template>
                  <template v-else>
                    <v-alert
                      dense
                      text
                      border="left"
                      type="error"
                      class="my-2"
                    >
                      Auftragserfassung nicht möglich
                    </v-alert>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col v-if="propertyResult && propertyResult.geo3857" md="6">
        <template>
          <!-- {{ formatPointArray(propertyResult.geo3857) }} -->
          <DetailMap
            :point="formatPointArray(propertyResult.geo3857)"
            :project-id="
              propertyResult.projectId ? propertyResult.projectId : undefined
            "
            :height="'470px'"
          />
        </template>
      </v-col>
    </v-row>
    <v-progress-circular
      v-if="loading"
      :size="120"
      color="primary"
      indeterminate
      class="circle-loader"
    >
    </v-progress-circular>
  </div>
</template>

<script>
import DetailMap from './DetailMap.vue';
import ProjectDetails from './ProjectDetails.vue';
import NotificationObject from '@/main/NotificationObject.js';
import AddressAutocomplete from '@/components/orderEntry/orderCreation/customerDataSelection/AddressAutocomplete.vue';
import { HTTP } from '../../main/httpClient';
// import { filter } from 'vue/types/umd';
import proj4 from 'proj4';
// import { fromLonLat } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import axios from 'axios';

proj4.defs(
  'EPSG:25832',
  '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);

proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');

register(proj4);

export default {
  name: 'AvailabilityForm',
  components: {
    DetailMap,
    ProjectDetails,
    AddressAutocomplete
  },
  props: {
    centered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    homes: 1,
    offices: 0,
    publicBuilding: false,
    wowi: false,
    xCoordinate: undefined,
    yCoordinate: undefined,
    mapsCoordinate: undefined,
    xCoordinateUtm: undefined,
    yCoordinateUtm: undefined,
    utmCoordinate: undefined,
    googleMapsFormat: true,
    mapZoom: 14,
    successMessage: false,
    errorMessage: false,
    errorMessageContent:
      'Beim Speichern der Adresse ist ein Fehler aufgetreten',
    helpDialog: false,
    addressesForVerification: [],
    invalidAddresses: [],
    invalidAddressHeaders: [
      { text: 'Adress-ID', value: 'ADDRESS_ID', width: '85' },
      { text: 'PLZ', value: 'CITYCODE' },
      { text: 'Ort', value: 'CITY' },
      { text: 'Ortsteil', value: 'DISTRICT' },
      { text: 'Straße', value: 'STREET' },
      { text: 'Hausnr.', value: 'HOUSENO' },
      { text: 'Hausnr.-Zusatz', value: 'HOUSENO_SUPPL', width: '100' },
      { text: 'Objektinfo-ID', value: 'OBJECT_INFO_ID', width: '100' },
      { text: 'G-AGS-Key', value: 'AGS27' }
    ],
    projects: [],
    aggregatedProjects: [],
    projectStrings: [],
    projectKpis: [],
    loading: false,
    detailsVisible: false,
    selectedAddress: undefined,
    projectId: undefined,
    selectedProject: undefined,
    selectedProjectId: undefined,
    selectedProjectName: undefined,
    distributionStatuses: [],
    constructionStatuses: [],
    selectedDistributionStatus: undefined,
    selectedConstructionStatus: undefined,
    selectedCounty: undefined,
    selectedCity: undefined,
    tariffModels: [],
    selectedTariffModel: undefined,
    searchText: undefined,
    mondayProjects: undefined,
    mondayItems: undefined,
    projectsReady: false,
    mondayReady: false,
    rawProjects: [],
    incompleteProjects: [],
    showIncompleteDialog: false,
    statusFilter: 'all',
    correctMode: false,
    address: {
      zipCode: null,
      city: null,
      street: null,
      houseNumber: null,
      houseNumberSupplement: null,
      alternativeLocationDescription: null,
      geo: null
    },
    districts: undefined,
    district: undefined,
    streetGeo: undefined,
    propertyResult: undefined,
    showAddressSearch: false,
    showFilter: true,
    errorTolerance: 0,
    noProjectDialog: false,
    projectDialog: false,
    projectDistance: undefined,
    tabs: null,
    projectIdForVerification: undefined,
    projectDistanceForVerification: undefined,
    projectIdLoading: false,
    // convertedPoint: undefined,
    // filtererdProjects: [],
    headers: [
      { text: 'Status', value: 'addr_gt_class_id', width: '85' },
      { text: 'G-AGS-Key', value: 'addr_id' },
      { text: 'PLZ', value: 'addr_gt_plz' },
      { text: 'Ort', value: 'addr_gt_ort' },
      { text: 'Ortsteil', value: 'addr_gt_ortsteil' },
      { text: 'Straße', value: 'addr_gt_str' },
      { text: 'Hausnr.', value: 'addr_gt_hnr', width: '100' },
      { text: 'Hausnr.-Zusatz', value: 'addr_gt_hnrz', width: '100' },
      { text: 'bearbeitet am', value: 'date_gt_edited' }
    ]
    // sortBy: [{ key: 'date_gt_edited', order: 'desc' }],
  }),
  computed: {
    activeDistribution() {
      if (
        this.propertyResult &&
        this.propertyResult.distributionStatusName &&
        this.propertyResult.projectId
      ) {
        const activePhases = [
          'cooperation',
          'presales',
          'sales',
          'final-sales',
          'ready-for-construction',
          'handover-to-construction',
          'built-project',
          'project-hold',
          'built-project-researched',
          'active-remarketing'
        ];
        if (activePhases.includes(this.propertyResult.distributionStatusName)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    addressesForVerificationFiltered() {
      const validNumbers = [3, 97, 98, 99];
      let filtered;
      if (this.statusFilter === 'all') {
        return this.addressesForVerification;
      } else if (validNumbers.includes(parseInt(this.statusFilter))) {
        filtered = this.addressesForVerification.filter(
          (item) =>
            item.addr_gt_class_id.toString() === this.statusFilter.toString()
        );
        console.log('filtered', filtered);
        return filtered;
      } else {
        return this.addressesForVerification;
      }
    },
    userGroups() {
      return this.$auth.user().userRoles;
    },
    convertedPoint() {
      const convertedPoint = [];
      if (
        this.xCoordinate &&
        this.yCoordinate &&
        !isNaN(this.xCoordinate) &&
        !isNaN(this.yCoordinate)
      ) {
        const origin = {
          x: this.xCoordinate,
          y: this.yCoordinate
        };
        const converted = proj4('EPSG:4326', 'EPSG:3857', origin);
        convertedPoint.push(parseFloat(converted.x));
        convertedPoint.push(parseFloat(converted.y));
      }
      if (convertedPoint.length === 2) {
        return convertedPoint;
      } else {
        return undefined;
      }
    },
    convertedPointForSave() {
      const convertedPoint = [];
      if (
        this.xCoordinate &&
        this.yCoordinate &&
        !isNaN(this.xCoordinate) &&
        !isNaN(this.yCoordinate)
      ) {
        const origin = {
          x: this.xCoordinate,
          y: this.yCoordinate
        };
        const converted = proj4('EPSG:4326', 'EPSG:25832', origin);
        convertedPoint.push(parseFloat(converted.x));
        convertedPoint.push(parseFloat(converted.y));
      }
      if (convertedPoint.length === 2) {
        return convertedPoint;
      } else {
        return undefined;
      }
    },
    tableHeaders() {
      let mergedArray;
      const baseArray = [
        { text: 'Projekt-ID', value: 'projektId', class: 'project-id' },
        { text: 'Projektname', value: 'name' },
        { text: 'Ort', value: 'city' },
        { text: 'Ortsteil', value: 'district' },
        { text: 'Landkreis', value: 'county' }
      ];
      const infoArray = [
        // { text: 'Status (intern)', value: 'projectStatus' },
        { text: 'Vertriebsphase', value: 'distributionStatus' },
        { text: 'Kostenträger-Nr', value: 'ktr' },
        // { text: 'Vertriebsphase', value: 'vPhase' },
        { text: 'Baustatus', value: 'constructionStatus' },
        // { text: 'Vorplanung', value: 'vPlan' },
        { text: 'Tarifmodell', value: 'tariffModel.name' },
        // { text: 'Vertriebsstatus', value: 'distributionStatus.description' },
        { text: 'Versandart', value: 'shippingMethod.description' }
      ];
      const kpiArray = [
        { text: 'BP+ (Plan)', value: 'buildingsPassedPlusPlan' },
        { text: 'HP (Plan)', value: 'homesPassedPlan' },
        { text: 'Aufträge (ist)', value: 'contractCount' },
        // { text: 'Homes Activated (Pay)', value: 'homesActivatedPay' },
        { text: 'BP+ Quote', value: 'buildingsPassedQuota' },
        { text: 'HP Quote', value: 'homesPassedQuota' }
      ];
      if (this.projectKpis && this.projectKpis.length) {
        mergedArray = [...baseArray, ...kpiArray, ...infoArray];
      } else {
        mergedArray = [...baseArray, ...infoArray];
      }
      return mergedArray;
    }
  },
  watch: {
    mapsCoordinate(newVal) {
      const regex = new RegExp(/^[0-9]*\.[0-9]+,\s[0-9]*\.[0-9]+$/);
      if (regex.test(newVal)) {
        const splitted = newVal.split(', ');
        this.yCoordinate = parseFloat(splitted[0]);
        this.xCoordinate = parseFloat(splitted[1]);
        this.getProjectAreaNearbyPoint();
      }
    },
    xCoordinateUtm(newVal) {
      if (this.yCoordinateUtm && newVal) {
        this.utmCoordinate =
          'POINT(' + newVal + ' ' + this.yCoordinateUtm + ')';
      }
    },
    yCoordinateUtm(newVal) {
      if (this.yCoordinateUtm && newVal) {
        this.utmCoordinate =
          'POINT(' + this.yCoordinateUtm + ' ' + newVal + ')';
      }
    },
    homes(newVal) {
      if (newVal < 1 && this.offices < 1) {
        this.offices = 1;
      }
    },
    offices(newVal) {
      if (newVal < 1 && this.homes < 1) {
        this.homes = 1;
      }
    },
    'address.street'(newVal) {
      if (!newVal) {
        return;
      }
      if (newVal.length >= 2) {
        this.getStreetCoordinate(
          this.address.zipCode,
          this.address.city,
          newVal
        );
      }
    },
    'address.city'(newVal) {
      if (!newVal) {
        return;
      }
      if (newVal.length >= 2 && !this.address.street) {
        this.streetGeo = undefined;
      }
    },
    successMessage(newVal) {
      if (newVal) {
        const _this = this;
        setTimeout(function () {
          _this.successMessage = false;
        }, 2500);
      }
    },
    errorMessage(newVal) {
      if (newVal) {
        const _this = this;
        setTimeout(function () {
          _this.errorMessage = false;
        }, 2500);
      }
    }
  },
  beforeMount() {
    this.loading = false;
  },
  async mounted() {
    this.loading = false;
  },
  methods: {
    getProjectAreaNearbyPoint(point) {
      let formattedPoint;
      if (point) {
        this.projectIdLoading = true;
        formattedPoint = 'POINT(' + point.toString().replace(',', ' ') + ')';
      } else {
        formattedPoint =
          'POINT(' + this.convertedPoint.toString().replace(',', ' ') + ')';
      }

      HTTP.get('/past/geo/project-area-nearby-point?point=' + formattedPoint)
        .then((response) => {
          if (response && response.data && response.data.projekt_id) {
            if (point) {
              this.projectIdForVerification = response.data.projekt_id;
              this.projectDistanceForVerification = response.data.distance;
              this.projectIdLoading = false;
            } else {
              this.projectId = response.data.projekt_id;
              this.projectDistance = response.data.distance;
              this.projectDialog = true;
            }
          } else {
            if (!point) {
              this.noProjectDialog = true;
            }
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
          this.projectIdLoading = false;
        });
    },
    formatPointArray(point) {
      const convertedPoint = [];
      if (point && point.includes('POINT(')) {
        const pointArray = point
          .replace('POINT(', '')
          .replace(')', '')
          .split(' ');
        convertedPoint.push(parseFloat(pointArray[0]));
        convertedPoint.push(parseFloat(pointArray[1]));
      }
      return convertedPoint;
    },
    getCollection(collection) {
      return HTTP.get('/past/' + collection)
        .then((response) => {
          this.loading = false;
          return response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getDistricts() {
      if (
        this.address &&
        this.address.zipCode &&
        this.address.city &&
        this.address.street
      ) {
        HTTP.get(
          '/past/geo/property-address?zip=' +
            this.address.zipCode +
            '&city=' +
            this.address.city +
            '&street=' +
            this.address.street
        )
          .then((response) => {
            this.loading = false;
            if (response && response.data && response.data.districts) {
              this.districts = response.data.districts;
              if (response.data.districts[0]) {
                this.district = response.data.districts[0];
              }
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },
    getProperty() {
      this.loading = true;
      const zipCode =
        this.address && this.address.zipCode ? this.address.zipCode : '';
      const city = this.address && this.address.city ? this.address.city : '';
      const street =
        this.address && this.address.street ? this.address.street : '';
      const houseNumber =
        this.address && this.address.houseNumber
          ? this.address.houseNumber
          : '';
      const houseNumberSupplement =
        this.address && this.address.houseNumberSupplement
          ? this.address.houseNumberSupplement
          : '';
      const errorToleranceMeters =
        this.errorTolerance && !isNaN(+this.errorTolerance)
          ? parseInt(this.errorTolerance)
          : 0;

      const queryString =
        '?zip=' +
        zipCode +
        '&city=' +
        city +
        '&street=' +
        street +
        '&houseno=' +
        houseNumber +
        '&errorToleranceMeters=' +
        errorToleranceMeters +
        '&housenoSuppl=' +
        houseNumberSupplement +
        '&similarity=100';

      HTTP.get('/past/geo/property-status' + queryString)
        .then((response) => {
          this.propertyResult = response.data ? response.data : undefined;
          this.$emit(
            'updatePropertyResult',
            response.data,
            this.address,
            this.activeDistribution
          );
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'warning',
              'Fehlerhafte Adresse oder Adresse bereits vorhanden'
            )
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveNewAddress() {
      const coordinateObject = {
        type: 'Point',
        coordinates: this.convertedPointForSave
      };

      const newAddressObject = {
        zip: this.address.zipCode,
        city: this.address.city,
        street: this.address.street,
        houseno: this.address.houseNumber,
        housenoSuppl: this.address.houseNumberSupplement
          ? this.address.houseNumberSupplement
          : null,
        district: this.district?.addr_gt_ortsteil,
        ags11: this.district?.addr_src_ags11,
        homes: this.homes ? this.homes : 0,
        offices: this.offices ? this.offices : 0,
        publicBuilding: this.publicBuilding ? 1 : 0,
        wowi: this.wowi ? 1 : 0,
        coordinates: coordinateObject,
        editor: this.$auth.user().userName
      };

      if (
        this.address &&
        this.address.zipCode &&
        this.address.city &&
        this.address.street &&
        this.address.houseNumber &&
        this.convertedPointForSave
      ) {
        axios
          .post('https://spot.goetel.de/save-new-address', newAddressObject, {
            headers: {
              Authorization: 'Basic cGFzdGFkbWluOjhOcDkyalYxcGU=',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          })
          .then((response) => {
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', 'Neue Adresse gespeichert')
            );
            this.address = {
              zipCode: null,
              city: null,
              street: null,
              houseNumber: null,
              houseNumberSupplement: null,
              alternativeLocationDescription: null,
              geo: null
            };
            this.district = undefined;
            this.mapsCoordinate = undefined;
            this.homes = 1;
            this.offices = 0;
            this.publicBuilding = false;
            this.wowi = false;
            this.successMessage = true;
            this.$refs.AddressAutocomplete.clearAddress();
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            console.log(error.response.data);
            if (error.response && error.response.data) {
              this.errorMessageContent = error.response.data;
            } else {
              this.errorMessageContent =
                'Beim Speichern der Adresse ist ein Fehler aufgetreten';
            }
            this.$store.commit(
              'addNotification',
              new NotificationObject('warning', this.errorMessageContent)
            );
            this.errorMessage = true;
            // HTTP.post('/past/geo/save-new-address', newAddressObject)
            //   .then((response) => {
            //     this.$store.commit(
            //       'addNotification',
            //       new NotificationObject('success', 'Neue Adresse gespeichert')
            //     );
            //     this.address = {
            //       zipCode: null,
            //       city: null,
            //       street: null,
            //       houseNumber: null,
            //       houseNumberSupplement: null,
            //       alternativeLocationDescription: null,
            //       geo: null
            //     };
            //     this.district = undefined;
            //     this.mapsCoordinate = undefined;
            //     this.homes = 1;
            //     this.offices = 0;
            //     this.publicBuilding = false;
            //     this.wowi = false;
            //     this.successMessage = true;
            //     this.$refs.AddressAutocomplete.clearAddress();
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //     this.$store.commit(
            //       'addNotification',
            //       new NotificationObject(
            //         'warning',
            //         'Feherhafte Adresse oder kein Ergebnis'
            //       )
            //     );
            //     this.errorMessage = true;
            //   })
            //   .finally(() => {
            //     this.loading = false;
            //   });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.commit(
          'addNotification',
          new NotificationObject('error', 'Adresse unvollständig')
        );
      }
    },
    loadAddressesForVerification() {
      HTTP.get('/past/geo/addresses-for-verification')
        .then((response) => {
          this.addressesForVerification = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject('warning', 'Es ist ein Fehler aufgetreten')
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadInvalidAddresses() {
      HTTP.get('/past/geo/invalid-address-queue')
        .then((response) => {
          this.invalidAddresses = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject('warning', 'Es ist ein Fehler aufgetreten')
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateAddress(address) {
      console.log(address);
      this.address = Object.assign(this.address, address.value);
      this.getDistricts();
    },
    updateGeo(geo) {
      this.address.geo = geo;
      this.mapZoom = 13;
    },
    getStreetCoordinate(zip, city, street) {
      this.loading = true;
      if (zip && city && street) {
        HTTP.get(
          '/past/geo/property-address?city=' +
            city +
            '&zip=' +
            zip +
            '&street=' +
            street
        )
          .then((response) => {
            if (
              response.data &&
              response.data.geojson3857 &&
              response.data.geojson3857.length
            ) {
              this.streetGeo = response.data.geojson3857;
              this.mapZoom = 16;
            }
          })
          .catch((error) => console.log(error))
          .finally(() => (this.loading = false));
      }
    },
    showDetails(item) {
      this.selectedAddress = item;
      this.detailsVisible = true;
      this.getProjectAreaNearbyPoint(JSON.parse(item.st_asgeojson).coordinates);
    },
    verificateAddress(gAgs, addrClassId, projectId, distance) {
      // verificateAddress(selectedAddress.addr_id, 0, projectIdForVerification, projectDistanceForVerification
      this.loading = true;
      let projectString = '';
      if (!addrClassId && addrClassId !== 0) {
        addrClassId = 99;
      }
      if (projectId && ((distance && distance === 0) || !distance)) {
        projectString = '&projectId=' + projectId;
      }
      if (gAgs && gAgs.length === 28) {
        HTTP.get(
          '/past/geo/verificate-address?gAgs=' +
            gAgs +
            '&addrClassId=' +
            addrClassId +
            '&editor=' +
            this.$auth.user().userName +
            projectString
        )
          .then((response) => {
            this.loadAddressesForVerification();
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', 'Adresse gespeichert')
            );
            this.detailsVisible = false;
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit(
              'addNotification',
              new NotificationObject('warning', 'Es ist ein Fehler aufgetreten')
            );
          })
          .finally(() => (this.loading = false));
      }
    },
    // validation rules
    mapsCoordinateRule: (v) => {
      if (!v) return 'Bitte geben Sie Koordinaten ein';
      if (!(v && /^[0-9]*\.[0-9]+,\s[0-9]*\.[0-9]+$/).test(v)) {
        return 'Bitte geben Sie die Koordinaten im Google Maps Format an, z.B. "51.54878, 9.90682"'; // const regex = new RegExp(/^[0-9]*\.[0-9]+,\s[0-9]*\.[0-9]+$/);
      }
      return true;
    },
    formatDate(value) {
      // const date = new Date(value);
      // return date.getDate() + '.' + date.getFullMonth() + '.' + date.getFullYear();
      const date = new Date(value);
      const result = date.toLocaleDateString('de-DE', {
        // you can use undefined as first argument
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return result;
    },
    formatDateTime(value) {
      // const date = new Date(value);
      // return date.getDate() + '.' + date.getFullMonth() + '.' + date.getFullYear();
      const date = new Date(value);
      const result = date.toLocaleTimeString('de-DE', {
        // you can use undefined as first argument
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return result;
    },
    getTooltipColor(id) {
      let color;
      switch (id) {
        case '3':
          color = '#56d373';
          break;
        case '99':
          color = '#555';
          break;
        case '98':
          color = '#886f00';
          break;
        case '97':
          color = '#ff6b6b';
          break;
        default:
          color = '#555';
      }
      return color;
    },
    getTooltipText(id) {
      let text;
      switch (id) {
        case '3':
          text = 'freigegeben';
          break;
        case '99':
          text = 'ungeprüft';
          break;
        case '98':
          text = 'Korrektur erforderlich';
          break;
        case '97':
          text = 'abgelehnt';
          break;
        default:
          text = 'ungeprüft';
      }
      return text;
    },
    correctAddress(selectedAddress) {
      let editor;
      if (selectedAddress && selectedAddress.addr_src_srctype) {
        editor = selectedAddress.addr_src_srctype
          .replace('SPOT(', '')
          .replace(')', '');
      }

      if (
        editor === this.$auth.user().userName ||
        this.userGroups.includes('camunda-admin') ||
        this.userGroups.includes('LgSpotAddressmaster')
      ) {
        this.detailsVisible = false;
        this.tabs = 1;
        this.correctMode = true;

        const addressObject = {
          zipCode: selectedAddress.addr_gt_plz,
          city: selectedAddress.addr_gt_ort,
          street: selectedAddress.addr_gt_str,
          houseNumber: selectedAddress.addr_gt_hnr,
          houseNumberSupplement: selectedAddress.addr_gt_hnrz
        };

        this.address = {
          zipCode: null,
          city: null,
          street: null,
          houseNumber: null,
          houseNumberSupplement: null,
          alternativeLocationDescription: null,
          geo: null
        };

        Object.assign(this.address, addressObject);

        this.homes =
          selectedAddress && selectedAddress.addr_gt_hh
            ? selectedAddress.addr_gt_hh
            : 0;
        this.offices =
          selectedAddress && selectedAddress.addr_gt_gew
            ? selectedAddress.addr_gt_gew
            : 0;
        this.publicBuilding = !!(
          selectedAddress && selectedAddress.addr_gt_oeff
        );
        this.wowi = !!(selectedAddress && selectedAddress.addr_gt_wowi);
      } else {
        alert('Es können nur selbst erstellte Adressen korrigiert werden.');
      }
    },
    updateCorrectedAddress() {
      if (this.selectedAddress && this.selectedAddress.addr_id) {
        const coordinateObject = {
          type: 'Point',
          coordinates: this.convertedPointForSave
        };

        const correctedObject = {
          addr_id: this.selectedAddress.addr_id,
          coordinates: coordinateObject,
          editor: this.$auth.user().userName
        };

        axios
          .post('https://spot.goetel.de/update-new-address', correctedObject, {
            headers: {
              Authorization: 'Basic cGFzdGFkbWluOjhOcDkyalYxcGU=',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          })
          .then((response) => {
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', 'Korrektur gespeichert')
            );
            this.address = {
              zipCode: null,
              city: null,
              street: null,
              houseNumber: null,
              houseNumberSupplement: null,
              alternativeLocationDescription: null,
              geo: null
            };
            this.district = undefined;
            this.mapsCoordinate = undefined;
            this.homes = 1;
            this.offices = 0;
            this.publicBuilding = false;
            this.wowi = false;
            this.successMessage = true;
            this.$refs.AddressAutocomplete.clearAddress();
            this.correctMode = false;
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            console.log(error.response.data);
            if (error.response && error.response.data) {
              this.errorMessageContent = error.response.data;
            } else {
              this.errorMessageContent =
                'Beim Speichern ist ein Fehler aufgetreten';
            }
            this.$store.commit(
              'addNotification',
              new NotificationObject('warning', this.errorMessageContent)
            );
            this.errorMessage = true;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.commit(
          'addNotification',
          new NotificationObject(
            'error',
            'Keine Berechtigung oder fehlende Daten'
          )
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.project-id {
  color: #ff0000;
}
h2 {
  font-size: 24px;
  color: #209bd8;
}
// .row ::v-deep .v-text-field__details {
//   // display: none !important;
// }
.project-table th {
  padding: 0 10px;
}
.v-text-field--outlined.v-select.v-select--chips ::v-deep input {
  min-height: auto;
}
.address-input {
  margin: 10px;
  .v-card__title {
    border-bottom: 1px solid #ddd;
  }
}
.small-card {
  margin-bottom: 10px;
  .v-card__title {
    font-size: 16px;
    color: #555;
    border-bottom: 1px solid #ddd;
    padding: 5px 16px;
    margin-bottom: 20px;
  }
}
.filter-card {
  margin: -1px 10px -5px 10px;
  padding: 1px 10px 0px 15px;
}
.table-card {
  margin: 15px 10px;
}
.verification-table,
table {
  .status-green {
    width: 30px;
    height: 30px;
    background-color: #80ed99;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #80ed99;
  }
  .status-red {
    width: 30px;
    height: 30px;
    background-color: #ff6b6b;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #ff6b6b;
  }
  .status-yellow {
    width: 30px;
    height: 30px;
    background-color: #ffe100;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #ffe100;
  }
  .status-grey {
    width: 30px;
    height: 30px;
    background-color: #aaa;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #aaa;
  }
}
.status-circle {
  float: left;
  margin-right: 15px;
  width: 20px !important;
  height: 20px !important;
}
.icon-spinner {
  animation: spin-animation 1.5s infinite;
  display: inline-block;
}
.rCard {
  border: 1px solid #ddd;
  border-radius: 2px;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.v-dialog table tr td:first-child {
  width: 50%;
}
.form-loading {
  filter: blur(0.2rem);
}
.circle-loader {
  z-index: 1000;
  margin: auto;
  position: fixed !important;
  left: calc(50vw - 60px);
  top: calc(50vh - 60px);
}
</style>
